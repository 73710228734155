import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useGetAggregatedMultipleGroupsWithChildrenQuery } from 'services/analytics'
import Graph from "./TSChart"
import DownloadCSV from '../DownloadCSV'
import Loader from "components/Progress";
import { useSnackbar } from "notistack";
import noData from "assets/img/lineChart.png";

const TSChart = (props) => {
  const groupAnalytics = useSelector((state) => state.groupAnalytics);
  const [data, setData] = useState([])
  const [csvData, setcsvData] = useState([])
  const { enqueueSnackbar } = useSnackbar();
  const filter = groupAnalytics.Comparative.filter
  const aggregatedData = useGetAggregatedMultipleGroupsWithChildrenQuery(
    {
      token: window.localStorage.getItem("token"),
      id: groupAnalytics.Comparative.solution,
      parameters: `?${groupAnalytics.Comparative.filter}`,
    },
    {
      skip: !filter || !props.load,
    }
  )

  function showSnackbar(title, message, variant, timeOut) {
    return enqueueSnackbar({ title, message, variant }, { timeOut });
  }

  function chkData(formatData) {
    for(let i=0; i<formatData.length; i++){
      if(formatData[i]?.readings?.length){
        return true
      }
    }
    props.setLoad(false)
    setcsvData([])
    setData([])
    showSnackbar("Analytics", "No Data Found", "error", 1000)
    return false
  }

  function toFixedIfNecessary( value, dp ){
    if(value){
      return +parseFloat(value).toFixed( dp );
    } else {
      return undefined
    }
  }

  useEffect(() => {
    console.log({aggregatedData})
    if(!aggregatedData.isFetching && aggregatedData.isSuccess){
      let formattedData = []
      let formattedDataforCSV = []
      aggregatedData?.data?.payload?.forEach(async (entry) => {
        console.log({entry})
        let name = ""
        if(entry?.parentChainMap?.length > 1){
          name += entry.parentChainMap[1] + " - "
        }
        if(entry?.parentChainMap?.length > 0){
          name += entry.parentChainMap[0] + " - "
        }
        name += entry?.currentGroupName
        if(entry?.reading?.data?.length || !entry.reading){
          let readings = entry?.reading?.data?.map((reading) => {
            formattedDataforCSV.push({
              name,
              date: reading.date,
              value: toFixedIfNecessary(reading[groupAnalytics.Comparative.aggregation], 2)
            })
            return {
              date: new Date(reading.date).getTime(),
              value: toFixedIfNecessary(reading[groupAnalytics.Comparative.aggregation], 2)
            }
          })
          formattedData.push({
            name: readings?.length ? name : name + " (No Data)",
            readings
          })
        }
        else{
          Object.keys(entry?.reading?.data).forEach((key) => {
            let nameWithDevice = ""
            if(entry?.parentChainMap.length){
              nameWithDevice += entry?.parentChainMap[0] + " - "
            }
            nameWithDevice +=  entry?.currentGroupName + " - " + key
            let readings = entry?.reading?.data[key].map((reading) => {
              formattedDataforCSV.push({
                name: key,
                date: reading.date,
                value: toFixedIfNecessary(reading[groupAnalytics.Comparative.aggregation], 2)
              })
              return {
                date: new Date(reading.date).getTime(),
                value: toFixedIfNecessary(reading[groupAnalytics.Comparative.aggregation], 2)
              }
            })
            formattedData.push({
              name: readings?.length ? nameWithDevice : nameWithDevice + " (No Data)",
              readings  
            })
          })
        } 
      })
      console.log({formattedDataforCSV})
      console.log({formattedData})
      if(formattedData.length > 20){
        formattedData = formattedData.slice(0, 20)
      }
      if(chkData(formattedData)){
        setData(formattedData)
        setcsvData(formattedDataforCSV)
      }
    } else if(!aggregatedData.isFetching && aggregatedData.isError){
      setData([])
      setcsvData([])
      props.setLoad(false)
    }
  }, [aggregatedData])
 
  useEffect(() => {
    props.setLoad(false)
  }, [data])

  return (
    <div
      style={{
        height: "100%",
        width: "100%"
      }}
    >
      {!props.load && !aggregatedData.isFetching ? 
        data.length ?
          (<Graph
            name = {"Highlighted graph"} 
            data={data}
          />) : aggregatedData?.data?.success ? (
            <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              // marginTop: "30px",
            }}
          >
            <img src={noData} height="80px" width="80px" />
            <p style={{ color: "#c7c7c7", marginBottom: "15px" }}>
              No data found
            </p>
          </div>
          ) : null :
        (
          <Loader />
        )
      }
      {!aggregatedData.isFetching && !props.load && csvData.length ? (
        <DownloadCSV data={csvData} fileName="ComparativeAnalytics_CSV"/>
      ):null}
    </div>
  )
}

export default TSChart