import keys from "Keys";
import { Mutex } from "async-mutex";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define a service using a base URL and expected endpoints

const baseQuery = fetchBaseQuery({
  baseUrl: keys.baseUrl,
});

const tokenMutex = new Mutex(); // Mutex to ensure only one call to /getToken is made at a time
let tokenPromise; // Promise to hold the /getToken request

async function getToken(api, extraOptions) {
  try {
    return await baseQuery(
      {
        url: "/getToken",
        method: "GET",
        mode: "cors",
        credentials: "include",
      },
      api,
      extraOptions
    );
  } catch (error) {
    throw new Error("Failed to obtain token");
  }
}

export default async function baseQueryWithReauth(args, api, extraOptions) {
  const mutex = new Mutex();
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);
  if (
    result?.error?.status === 400 && result?.error?.data?.message === "service not found"
  ) {
    tokenPromise = false
    window.localStorage.removeItem("metaData");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("user");
    window.location.href = "/auth/login"; // Redirect to the login page
    return false;
  }
  if (
    result.error &&
    (result.error.status === 403 || result.error.status === 401 || result?.error?.data?.errorCode === 401)
  ) {
    if (
      result?.error?.status === 401 || result?.error?.data?.errorCode === 401
    ) {
      tokenPromise = false
      window.localStorage.removeItem("metaData");
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("user");
      window.location.href = "/auth/login"; // Redirect to the login page
      /* setTimeout(() => {
        window.location.reload();
      }, 1000); */
      return false;
    }
    try {
      await tokenMutex.runExclusive(async () => {
        if (!tokenPromise) {
          tokenPromise = getToken(api, extraOptions);
        }
        const refreshResult = await tokenPromise;
        tokenPromise = false;
        if (refreshResult.data) {
          window.localStorage.setItem(
            "token",
            refreshResult.data.payload.token
          );
          result = await baseQuery(
            {
              url: args.url,
              headers: {
                ...args.headers,
                "x-access-token": refreshResult.data.payload.token,
              },
            },
            api,
            extraOptions
          );
        } else {
          window.localStorage.removeItem("metaData");
          window.localStorage.removeItem("token");
          window.localStorage.removeItem("user");
          window.location.href = "/auth/login"; // Redirect to the login page
          /* setTimeout(() => {
            window.location.reload();
          }, 1000); */
        }
      });
    } catch (error) {
      console.error("Error obtaining token:", error);
    }
  }
  return result;
}
