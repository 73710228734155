import React, { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Options from "./Options"
import TSChart from "./TSChart"

const ComparativeAnalytics = (props) => {
  const [load, setLoad] = useState(false)
  
	return(
    <Card
      style={{
        height: "100%",
        verticalAlign: "middle",
        position: "relative",
      }}
    >
			<Grid container>
				<Grid xs={3}>
					<Options
            load={load} 
            setLoad = {setLoad}
          />
				</Grid>
				<Grid xs={9}>
					<TSChart
            load = {load}
            setLoad = {setLoad} 
          />
				</Grid>
			</Grid>
    </Card>
	)
}

export default ComparativeAnalytics