import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { useGetAggregatedMultipleGroupsWithChildrenQuery } from 'services/analytics'
import { useSnackbar } from 'notistack';
import noData from 'assets/img/lineChart.png';
import Graph from "../TSChart";

export default function ESDChart(props) {
    return (
        <>
        <div
            style={{
                height: "100%",
                width: "100%"
            }}
            >
            <Graph
                name = {"Highlighted graph"}
                data={props.graphData}
            />
        </div>
        </>
    )
}