import React, { useRef, useLayoutEffect, useEffect } from "react";
import { useSelector } from "react-redux";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5exporting from "@amcharts/amcharts5/plugins/exporting";
import * as am5themes from "@amcharts/amcharts5/themes/Animated"

import trendForecastConnector from "components/Freeform Analytics/Trend Forecasting/connector";
import freeformConnector from "components/Freeform Analytics/Freeform Chart/connector";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExcel,
  faFilePdf,
  faFileImage,
} from "@fortawesome/free-solid-svg-icons";
import EventEmitter from "eventemitter3";

const eventEmitter = new EventEmitter();

const useStyles = makeStyles({
  chartdiv: {
    width: "100%",
    height: "500px",
    maxWidth: "100%"
  }
});

let chartData = {};
var root = {};
var chart = {};
var xAxis = {};
var yAxis;
var series;
var legend = {};
let lastData;

const TSChart = (props) => {
  
  useEffect(() => {
    root[props.name] = am5.Root.new(props.name);
    root[props.name]._logo.dispose();
    const myTheme = am5.Theme.new(root);
    myTheme.rule("AxisLabel", ["minor"]).setAll({
      dy:1
    });
    myTheme.rule("Grid", ["x"]).setAll({
      strokeOpacity: 0.05
    });
    myTheme.rule("Grid", ["x", "minor"]).setAll({
      strokeOpacity: 0.05
    });
    
    // // Set themes
    // // https://www.amcharts.com/docs/v5/concepts/themes/
    // root.setThemes([
    //   am5.Theme.new(root),
    //   myTheme
    // ]);
    
    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    chart[props.name] = root[props.name].container.children.push(am5xy.XYChart.new(root[props.name], {
      panX: true,
      panY: true,
      wheelX: "panX",
      wheelY: "zoomX",
      maxTooltipDistance: 0,
      pinchZoomX:true
    }));
    
    
    var date = new Date();
    date.setHours(0, 0, 0, 0);
    var value = 100;
    
    function generateData() {
      value = Math.round((Math.random() * 10 - 4.2) + value);
      am5.time.add(date, "day", 1);
      return {
        date: date.getTime(),
        value: value
      };
    }
    
    function generateDatas(count) {
      var data = [];
      for (var i = 0; i < count; ++i) {
        data.push(generateData());
      }
      return data;
    }
    
    
    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    xAxis[props.name] = chart[props.name].xAxes.push(am5xy.DateAxis.new(root[props.name], {
      maxDeviation: 0.2,
      baseInterval: {
        timeUnit: "hour",
        count: 1
      },
      renderer: am5xy.AxisRendererX.new(root[props.name], {
        minorGridEnabled: true
      }),
      tooltip: am5.Tooltip.new(root[props.name], {})
    }));
    
    var yAxis = chart[props.name].yAxes.push(am5xy.ValueAxis.new(root[props.name], {
      renderer: am5xy.AxisRendererY.new(root[props.name], {})
    }));
    
    
    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    props.data.forEach((data) => {
      console.log({data})
      series = chart[props.name].series.push(am5xy.LineSeries.new(root[props.name], {
        name: data.name,
        xAxis: xAxis[props.name],
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        legendValueText: "{valueY}",
        tooltip: am5.Tooltip.new(root[props.name], {
          pointerOrientation: "horizontal",
          labelText: "{valueY}"
        }),
      }));

      series.strokes.template.setAll({
        strokeWidth: 3,
      });
    
      series.data.setAll(data.readings || []);
    
      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();
    }) 
    
    
    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart[props.name].set("cursor", am5xy.XYCursor.new(root[props.name], {
      behavior: "none"
    }));
    cursor.lineY.set("visible", false);
    
    
    // // Add scrollbar
    // // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    // chart.set("scrollbarX", am5.Scrollbar.new(root, {
    //   orientation: "horizontal"
    // }));
    
    // chart.set("scrollbarY", am5.Scrollbar.new(root, {
    //   orientation: "vertical"
    // }));
    
    
    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    legend[props.name] = chart[props.name].rightAxesContainer.children.push(am5.Legend.new(root[props.name], {
      paddingLeft: 15,
      height: am5.percent(100)
    }));
    
    // When legend item container is hovered, dim all the series except the hovered one
    legend[props.name].itemContainers.template.events.on("pointerover", function(e) {
      var itemContainer = e.target;
    
      // As series list is data of a legend, dataContext is series
      var series = itemContainer.dataItem.dataContext;
    
      chart[props.name].series.each(function(chartSeries) {
        if (chartSeries != series) {
          chartSeries.strokes.template.setAll({
            strokeOpacity: 0.15,
            stroke: am5.color(0x000000)
          });
        } else {
          chartSeries.strokes.template.setAll({
            strokeWidth: 3
          });
        }
      })
    })
    
    // When legend item container is unhovered, make all series as they are
    legend[props.name].itemContainers.template.events.on("pointerout", function(e) {
      var itemContainer = e.target;
      var series = itemContainer.dataItem.dataContext;
    
      chart[props.name].series.each(function(chartSeries) {
        chartSeries.strokes.template.setAll({
          strokeOpacity: 1,
          stroke: chartSeries.get("fill")
        });
      });
    })
    
    legend[props.name].itemContainers.template.set("width", am5.p100);
    legend[props.name].labels.template.setAll({
      fontSize: 10,
      fontWeight: "700"
    });

    legend[props.name].valueLabels.template.setAll({
      fontSize: 10,
      textAlign: "right"
    });
    
    // It's is important to set legend data after all the events are set on template, otherwise events won't be copied
    legend[props.name].data.setAll(chart[props.name].series.values);
    
    
    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart[props.name].appear(1000, 100);

    return () => {
      root[props.name].dispose();
    };
  }, [])

  return (
    <div
      style={{
        overflow: "hidden",
        // paddingBottom: "56.25%",
        position: "relative",
        height: "100%",
      }}
    >
      <div
        id={props.name}
        style={{
          position: "absolute",
          left: "0",
          top: "0",
          height: "100%",
          // maxHeight: "480px",
          width: "100%",
        }}
      ></div>
    </div>
  );
}

export default TSChart