import React, {useState, useEffect} from "react";
import { 
	FormControl, 
	InputLabel, 
	Select, 
	MenuItem, 
	Divider, 
	FormGroup,
	FormControlLabel,
	Switch,
	TextField,
	Card,
	Button
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { setComparative } from "rtkSlices/GroupAnalyticsSlice";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Groups from "components/Groups";
import { format, parseISO } from 'date-fns';

const Options = (props) => {
	const dispatch = useDispatch()
	const metaDataValue = useSelector((state) => state.metaData);
	const groupAnalytics = useSelector((state) => state.groupAnalytics);
	const { enqueueSnackbar } = useSnackbar();

	const [path, setPath] = useState(["0:All assets"])
	const [error, setError] = React.useState("");
	const [plotAssets, setPlotAssets] = useState(groupAnalytics.Comparative.plotAssets)
	const [toggleGroups, setToggleGroups] = useState(false)

	useEffect(() => {
		dispatch(
			setComparative({
				solution:
					groupAnalytics.Comparative.solution ||
					metaDataValue.services[0].id,
				datapoint:
					groupAnalytics.Comparative.datapoint ||
					metaDataValue.services[0].sensors[0].name,
				plotAssets: 
					groupAnalytics.Comparative.plotAssets ||
					false,
				filters: groupAnalytics.Comparative.filters || "selected",
				end: groupAnalytics.Comparative.end || new Date().toISOString(),
				start:
					groupAnalytics.Comparative.start ||
					new Date(
						new Date().setDate(new Date().getDate() - 1)
					).toISOString(),
			})
		)

		return () => {};
  }, []);

	useEffect(() => {
		chkError(groupAnalytics.Comparative.start, groupAnalytics.Comparative.end)
		chkMode()
	}, [groupAnalytics.Comparative.end, groupAnalytics.Comparative.start])

	useEffect(() => {
		chkError(groupAnalytics.Comparative.start, groupAnalytics.Comparative.end)
	}, [groupAnalytics.Comparative.aggregationInterval])

	function showSnackbar(title, message, variant, timeOut) {
    return enqueueSnackbar({ title, message, variant }, { timeOut });
  }

  function chkError(start, end) {
    let err = "";
    var one_day = 1000 * 60 * 60 * 24;
    var startDate = new Date(start);
    var endDate = new Date(end);
    var diff = Math.ceil((endDate.getTime() - startDate.getTime()) / one_day);
    if (diff > 7 && groupAnalytics.Comparative.aggregationInterval == "hourly"){
			err = "Please select date range within 7 days or set aggregation interval as daily.";
		}
		setError(err);
  }

	function chkMode() {
		var one_day = 1000 * 60 * 60 * 24;
    var startDate = new Date(groupAnalytics.Comparative.start);
    var endDate = new Date(groupAnalytics.Comparative.end);
    var diff = Math.ceil((endDate.getTime() - startDate.getTime()) / one_day);
    if (diff > 2 && groupAnalytics.Comparative.aggregationInterval == "hourly"){
			dispatch(
				setComparative({
					aggregationInterval: "daily"
				})
			)
			setError("")
		}
	}

	// function checkDisable() {
  //   return (
  //     error != "" ||
  //     (groupAnalytics.Comparative.datapoint ==
  //       groupAnalytics[props.type].cache.datapoint &&
  //       groupAnalytics[props.type].aggregation ==
  //         groupAnalytics[props.type].cache.aggregation &&
  //       groupAnalytics[props.type].solution ==
  //         groupAnalytics[props.type].cache.solution &&
  //       groupAnalytics[props.type].start ==
  //         groupAnalytics[props.type].cache.start &&
  //       groupAnalytics[props.type].end ==
  //         groupAnalytics[props.type].cache.end &&
  //       groupAnalytics[props.type].group?.id ==
  //         groupAnalytics[props.type].cache.group?.id)
  //   );
  // }

	function handleExpand() {
    let elm = document.getElementById("pre-canned-group");
    let arrow = document.getElementById("pre-canned-arrow");
    if (toggleGroups) {
      arrow.style.transform = "rotate(180deg)";
			elm.style.height = "100%"
    } else {
      arrow.style.transform = "rotate(360deg)";
      elm.style.height = "55px";
    }
  }

	useEffect(() => {
		console.log("Groups Dropdown toggled")
		console.log({toggleGroups})
		handleExpand()
	}, [toggleGroups])

	const handleSolution = (e) => {
		setToggleGroups(false)
    let datapoint = metaDataValue.services.find(
      (elm) => elm.id == e.target.value
    ).sensors[0].name;
		dispatch(
			setComparative({
				group: {name: "All assets", id: ""},
				selectedGroups: [],
        solution: e.target.value,
				datapoint: datapoint,
			})
		)
	}

	const setGroup = (group) => {
		dispatch(
			setComparative({
				group: {
					name: group.name,
					id: group.id,
				},
			})
		)
  };

	const handleToggle = (state) => {
		dispatch(
			setComparative({
				plotAssets: state
			})
		)
	}

	const handleAggregation = (e) => {
    dispatch(setComparative({ aggregation: e.target.value }));
  };

	const handleAggregationInterval = (e) => {
		dispatch(setComparative({ aggregationInterval: e.target.value}))
	}

  const handleStart = (newValue) => {
    if (Object.prototype.toString.call(newValue) === "[object Date]") {
      if (!isNaN(newValue)) {
				dispatch(setComparative({ start: new Date(newValue).toISOString() }));
      }
    }
  };

  const handleEnd = (newValue) => {
		console.log("changing")
    if (Object.prototype.toString.call(newValue) === "[object Date]") {
      if (!isNaN(newValue)) {
				dispatch(setComparative({ end: new Date(newValue).toISOString() }));
      }
    }
  };

  const handleDatapoint = (e) => {
		dispatch(
			setComparative({
				datapoint: e.target.value,
			})
		);
  };

  const handleFilters = (e) => {
	dispatch(
		setComparative({
			filters: e.target.value
		})
	)
  }

	const handleCheck = (id, childGroups) => {
		let groups = groupAnalytics.Comparative.selectedGroups
		if(groups.length && groups.includes(id)){
			groups = groups.filter((selectedId) => selectedId !== id)
		} else {
			groups = [...groups, id]	
		}
		dispatch(
			setComparative({
				selectedGroups: groups
			})
		)
	}

  const getDatapoints = (solution) => {
    let res = [];
    let service = metaDataValue.services.find((e) => e.id == solution);
    if (service) {
      res = service.sensors;
    }
    return res;
  };

	const loadGraph = () => {
		if(groupAnalytics.Comparative.selectedGroups.length){
			dispatch(
				setComparative({
					filter: `dateFrom=${groupAnalytics.Comparative.start}
					&dateTo=${groupAnalytics.Comparative.end}
					&aggregation=${groupAnalytics.Comparative.aggregation}
					&groupId=${JSON.stringify(groupAnalytics.Comparative.selectedGroups)}
					&dataPoint=${groupAnalytics.Comparative.datapoint}
					&mode=${groupAnalytics.Comparative.aggregationInterval}
					&filters=${groupAnalytics.Comparative.filters}
					${groupAnalytics.Comparative.plotAssets ? "&plotAssets=true" : ""}
					&includeName=true`
				})
			)
			props.setLoad(true)
		} else {
			showSnackbar("Analytics", "Atleast one group must be selected to load data", "error", 1000);
		}
	}

	return (
		<Card
			style={{
				margin: "10px",
				marginBottom: "29px",
				height: "calc(100vh - 240px)",
				verticalAlign: "middle",
				position: "relative",
				backgroundColor: "rgb(242, 242, 242)"
			}}
		>
			<p
				style={{
					margin: "11px",
					fontWeight: "bold",
					fontSize: "15px"
				}}
			>
				Report Settings
			</p>
			<div style={{height: "calc(100% - 85px)", overflowY: "scroll", overflowX: "hidden"}}>
				<FormControl style={{margin: "5px", marginLeft: "10px", width: "97%"}}>
					<InputLabel>Solution</InputLabel>
					<Select
						value={groupAnalytics.Comparative.solution}
						label="Solution"
						onChange={handleSolution}
					>
						{metaDataValue.services.map((elm) => {
							return <MenuItem value={elm.id}>{elm.name}</MenuItem>;
						})}
					</Select>
				</FormControl>
				<div
					style={{
						position: "relative",
						margin: "10px 0px",
						left: "10px",
						width: "97%"
					}}
				>
					<div
						style={{
							position: "absolute",
							top: "-12px",
							left: "11px",
							background: "rgb(242, 242, 242)",
							color: "#9b9b9b",
							fontSize: "13px",
							padding: "0 5px",
							zIndex: 1,
						}}
					>
						<p style={{ userSelect: "none" }}>Group</p>
					</div>
					<div className="pre-canned-group" id="pre-canned-group">
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								height: "55px",
								width: "100%",
								padding: "0 15px",
							}}
							onClick={() => {setToggleGroups((prev) => {
								return !prev
							})}}
						>
							<p>{`Groups`}</p>
							<div style={{display: "flex", gap: "7px", justifyContent: "center", alignItems: "center"}}>
								<FormGroup style={{display: "flex", justifyContent: "center"}}>
									{/* <FormControlLabel 
										control={
											<Switch
												color="primary"
												checked={plotAssets}
												onChange={() => {
													setPlotAssets((prev) => {
														handleToggle(!prev)
														return !prev
													})
												}} 
											/>
										} 
										label="Plot Assets" 
										labelPlacement="start"
									/> */}
								</FormGroup>
								<ArrowDropDownIcon
									id="pre-canned-arrow"
									style={{
										color: "#757575",
										position: "relative",
										left: "8px",
									}}
								/>
							</div>
							</div>
						<Divider style={{ margin: "0 10px 10px 10px" }} />
						<div style={{ padding: "20px" }}>
							<Groups
								id={groupAnalytics.Comparative.solution}
								selectedGroups={groupAnalytics.Comparative.selectedGroups}
								setGroup={setGroup}
								refetch={false}
								serviceDashboard={true}
								path={path}
								setPath={setPath}
								showCheck={true}
								handleCheck = {handleCheck}
								hideAdmin = {true}
							/>
						</div>
					</div>
				</div>
				<FormControl style={{margin: "5px", marginLeft: "10px", width: "97%"}}>
					<InputLabel>Filters</InputLabel>
					<Select
						value={groupAnalytics.Comparative.filters}
						label="Filters"
						onChange={handleFilters}
					>
						{[
							{
								text: "Only Selected Groups",
								value: "selected"
							},
							{
								text: "Entire Hierarchy (including selected groups)",
								value: "all"
							},
							{
								text: "Show Child Groups (upto three levels down)",
								value: "children"
							},
							{
								text: "Show Assets Only",
								value: "assets"
							}
						].map(
							(elm) => {
								return (
									<MenuItem value={elm.value}>{elm.text}</MenuItem>
								);
							}
						)}
					</Select>
				</FormControl>
				<FormControl style={{margin: "5px", marginLeft: "10px", width: "97%"}}>
					<InputLabel>Datapoint</InputLabel>
					<Select
						value={groupAnalytics.Comparative.datapoint}
						label="Datapoint"
						onChange={handleDatapoint}
					>
						{getDatapoints(groupAnalytics.Comparative.solution).map(
							(elm) => {
								return (
									<MenuItem value={elm.name}>{elm.friendlyName}</MenuItem>
								);
							}
						)}
					</Select>
				</FormControl>
				<FormGroup style={{marginTop: "10px", marginRight: "5px", marginLeft: "10px", width: "97%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
					<FormControl style={{width: "47.5%"}}>
						<InputLabel>Aggregation Interval</InputLabel>
						<Select
							value={groupAnalytics.Comparative.aggregationInterval}
							label="Aggregation Interval"
							onChange={handleAggregationInterval}
						>
							<MenuItem value={"hourly"}>Hourly</MenuItem>
							<MenuItem value={"daily"}>Daily</MenuItem>
						</Select>
					</FormControl>
					<FormControl style={{width: "47.5%"}}>
						<InputLabel>Aggregation</InputLabel>
						<Select
							label="Aggregation"
							value={groupAnalytics.Comparative.aggregation}
							onChange={handleAggregation}
						>
							<MenuItem value={"readingPerInterval"}>Mean</MenuItem>
							<MenuItem value={"min"}>Min</MenuItem>
							<MenuItem value={"max"}>Max</MenuItem>
							<MenuItem value={"sumOfReadings"}>Sum</MenuItem>
						</Select>
					</FormControl>
				</FormGroup>
				<div
					style={{marginTop: "15px", marginRight: "5px", marginLeft: "10px", width: "97%", display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "16px"}}
				>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimePicker
							label="Start Time"
							inputFormat="dd/MM/yyyy h:mm:ss aaa"
							value={parseISO(groupAnalytics.Comparative.start)}
							onChange={handleStart}
							renderInput={(params) => (
								<TextField
									{...params}
									fullWidth
									style={{ width: "47.5%" }}
								/>
							)}
						/>
					</LocalizationProvider>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateTimePicker
							label="End Time"
							inputFormat="dd/MM/yyyy h:mm:ss aaa"
							value={parseISO(groupAnalytics.Comparative.end)}
							onChange={handleEnd}
							renderInput={(params) => (
								<TextField
									{...params}
									fullWidth
									style={{ width: "47.5%" }}
								/>
							)}
						/>
					</LocalizationProvider>
				</div>
			</div>
			<div style={{position: "absolute", bottom:"5px", right: "5px"}}>
				<Button color="primary" onClick={loadGraph} disabled={!groupAnalytics.Comparative.selectedGroups.length || props.load}>
					Load
				</Button>			
			</div>
		</Card>
	)
}

export default Options