import React from 'react';
import { Button, Fab, Tooltip, Zoom } from '@mui/material';
import DownloadIcon from "@mui/icons-material/Download";

const DownloadCSV = ({ data, fileName }) => {
  const convertToCSV = (objArray) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '') line += ',';

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  };

  const downloadCSV = () => {
    const csvData = new Blob([convertToCSV(data)], { type: 'text/csv' });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement('a');
    link.href = csvURL;
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Tooltip
      title={"Download CSV"}
      placement='left'
      arrow
      TransitionComponent={Zoom}
    >
      <Fab size="small" color="secondary" style={{position: "absolute", right: "10px", bottom: "10px"}} onClick={downloadCSV} aria-label="downlaod">
        <DownloadIcon />
      </Fab>
    </Tooltip>
  );
}

export default DownloadCSV;