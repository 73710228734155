import React, {useState} from 'react'
import { Dialog, DialogTitle, DialogContent, TextField, IconButton, Button, DialogActions } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const index = (props) => {
  const {metaData, open, setOpen, onSubmit} = props
  const [metaDataState, setMetaDataState] = useState(metaData)
  const [error, setError] = useState("")
  const [errorIndexes, setErrorIndexes] = useState([])

  const handleAddMetaData = () => {
    let errorArray = []
    metaDataState.forEach((metaData, i) => {
      if(metaData.key == ""){
        errorArray.push(i)
      }
    })
    setErrorIndexes(errorArray)
    if(errorArray.length){
      setError("Key cannot be empty")
      return
    } else{
      setError("")
      setMetaDataState((prev) => [...prev, {key: "", value: "", restricted: false}])
    }
  }

  const handleRemoveMetaData = (selected) => {
    setMetaDataState((prev) => prev.filter((_, index) => index !== selected))
  }

  const handleKeyChange = (e, selected) => {
    setMetaDataState((prev) => prev.map((meta, index) => {
      if(index == selected){
        return {
          ...meta,
          key: e.target.value
        }
      } else {
        return meta
      }
    }))
  }

  const handleValueChange = (e, selected) => {
    setMetaDataState((prev) => prev.map((meta, index) => {
      if(index == selected){
        return {
          ...meta,
          value: e.target.value
        }
      } else {
        return meta
      }
    }))
  }

  return (
    <Dialog
      open={open}
      onClose={() => {setOpen(false)}}
      PaperProps={{ style: { width: "500px" } }}
    >
      <DialogTitle>
        Group Meta Data Management
      </DialogTitle>
      <DialogContent>
        <div style={{display: "flex", flexDirection: "column"}}>
          <Button 
            style={{
              textTransform: "none",
              borderRadius: "10px",
              color: "white",
              alignSelf: "flex-end",
              display: "flex",
              alignItems: "center",
              gap: "5px"
            }} 
            color="success" 
            variant="contained" 
            onClick={handleAddMetaData}
          >
            <AddCircleIcon />
            Add New
          </Button>
          {metaDataState.length ? (
            <div 
              style={{
                margin: "10px 5px", 
                marginBottom: "25px",
                height: "100%",
                maxHeight: "50vh",
                overflowY: "scroll"
              }}
            >
              {metaDataState.map((metaData, index) => (
                <div key={index} style={{display: "flex", justifyContent: "space-between", alignItems: "center", gap:"10px" }}>
                  <TextField
                    id="key"
                    margin="dense"
                    label="Key"
                    sx={{width: "95%"}}
                    value={metaData.key}
                    onChange={(e) => {handleKeyChange(e, index)}}
                    disabled={metaData.restricted}
                    error={errorIndexes.includes(index)}
                  />
                  <TextField
                    id="value"
                    margin="dense"
                    label="Value"
                    sx={{width: "95%"}}
                    value={metaData.value}
                    onChange={(e) => {handleValueChange(e, index)}}
                  />
                  <IconButton disabled={metaData.restricted} onClick={() => {handleRemoveMetaData(index)}} >
                    <RemoveCircleIcon color={metaData.restricted ? "disabled" : "error"}/>
                  </IconButton>
                </div>
              ))}
            </div>
          ) : (
            <div style={{height: "80px", marginBottom: "15px", display: "flex", justifyContent: "center", alignItems: "center", color: "lightgray"}}>
              <p>
                No Group Meta Data Added for this service
              </p>
            </div>
          )
          }
        </div>
        {error ? (
          <p style={{color: "red"}}>
            {error}
          </p>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={() => {
            setError("")
            setErrorIndexes([])
            setMetaDataState(metaData)
            setOpen(false)
          }} 
          color="error"
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            let errorArray = []
            metaDataState.forEach((metaData, i) => {
              if(metaData.key == ""){
                errorArray.push(i)
              }
            })
            if(errorArray.length){
              setErrorIndexes(errorArray)
              setError("Key cannot be empty")
            } else {
              setErrorIndexes([])
              setError("")
              onSubmit(metaDataState)
              setOpen(false)
            }
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default index