import React, { useEffect } from "react";
// import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

// const useStyles = makeStyles(styles);

export default () => {
  // const classes = useStyles();

  useEffect(() => {}, []);

  return <div>Coming Soon...</div>;
};
