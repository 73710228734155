import React, { useRef, useLayoutEffect, useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import ESDDataPointTrend from "../TrendChart";
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import EnergySavingIcon from "assets/icons/energy_saving.png";


const TrendWidget = (props) => {
    return (
      <div style={{width: "50%", float: "left", textAlign: "center", justifyContent: "center"}}>
         {/* <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F5F5F5', borderRadius: '12px', width: '100%', overflow:"hidden", height: "170px" }}> */}
         {/* <div style={{padding: "11px 10px 0 10px"}}> */}
              {/* Title */}
              {/* <Tooltip title={props.datapoint} placement="top" arrow> */}
              <div style={{ 
                  fontSize: '16px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: "#000",
                  padding: "0px 9px 0px 11px",
                  // fontWeight: "600",
                  letterSpacing: "1px"
                  // maxWidth: "242px" 
                  }}>
                  {props.datapoint}
              </div>
              {/* </Tooltip> */}

              <div style={{ margin: "15px", minWidth: "30px"}}>
                {/* <img
                  src={props.icon}
                  style={{ width: "30px" }}
                /> */}
                <img
                  src={EnergySavingIcon}
                  style={{ width: "60px", height: "71px", marginTop: "-6px", marginBottom: "-7px" }}
                />
               {/*  <ElectricBoltIcon 
                  fontSize="large"
                  style={{ width: "30px", height: "30px", color: "#F9D11D" }}
                /> */}
              </div>
  
              {/* Reading and Icon */}
              <Stack direction="column" spacing={2} style={{ marginTop: "3%", alignItems: "center", justifyContent: "center" }}>
                <div style={{ display: "flex", alignItems: "center" }} className='airflowrate-div'>
                  <div style={{ display: "flex" }}>
                    
                    <div style={{ marginTop: "4px" }}>
                      <div
                        className="energy-value"
                        style={{
                          fontSize: "25px",
                          color: "#000",
                          fontWeight: "bold",
                          marginLeft: "22px",
                        }}
                      >{Number.isInteger(props.reading) ? props.reading : props.reading.toFixed(1)}
                      <span style={{
                        fontSize: "17px",
                        color: "#000",
                        fontWeight: "400",
                        marginLeft: "3px",
                      }}>{props.unit}</span>
                      
                      </div>
                      {/* <div
                      className="energy-unit"
                        style={{
                          color: "#888",
                          fontWeight: "bold",
                          opacity: "0.5",
                          fontSize: "13px",
                          marginTop: "3px",
                          // textAlign: "end",
                        }}
                      >
                        <div style={{ 
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: "65px",
                            textAlign: "right" }}>
                              {props.unit}
                        </div>
                        
                      </div> */}
                    </div>
                  </div>
                </div>
              </Stack>
  
            
           {/* </div> */}
          {/* <ESDDataPointTrend
            name={props.name}
            data={props.data} 
            color={props.color}
          /> */}
           {/* </div> */}
          </div>
    )    
}

export default TrendWidget