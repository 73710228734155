//-------------CORE-------------//
import React, { Fragment, useEffect, useState } from "react";
//-------------MUI-------------//
import { Grid } from "@mui/material";

import { useGetGroupAggregatedReadingQuery, useGetGroupAggregatedMultipleParameterReadingQuery } from "services/analytics";
import { useSelector, useDispatch } from "react-redux";
import { setFilter } from "rtkSlices/filterDevicesSlice";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { CircularProgress, ListItemText } from "@mui/material";
import HumidityIcon from "../../../../assets/icons/kpihicon.png";
import KpiQr from "../../../../assets/icons/kpiqr.png";
import Drawer from "@mui/material/Drawer";
import Filters from "../../Default/Asset Views/Settings";
import { makeStyles, withStyles } from "@mui/styles";
import Rensair from "../../../../assets/icons/rensair-logo.png";
import CloseIcon from "@mui/icons-material/Close";
import CloudLogo from "../../../../assets/icons/cloud_logo.svg";
import { ScatterPlotOutlined } from "@mui/icons-material";
import ESDStats from "./ESDStats";
import ESDDataPointTrend from './ESDDataPointTrend';
import ESDChart from './ESDChart';
import SettingsIcon from "@mui/icons-material/Settings";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import Divider from "@mui/material/Divider";
import DollarOn from "assets/icons/dollarOn.png";
import EnergyOn from "assets/icons/energy-on.png";
import AirFlowRateIcon from "assets/icons/esd-airflowrate.png";
import TemperatureIcon from "assets/icons/esd-temperature.png";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import TuneIcon from '@mui/icons-material/Tune';
import Checkbox from "@mui/material/Checkbox";
import { useSnackbar } from "notistack";
import Loader from "components/Progress";
import noData from "assets/img/lineChart.png";
import { Fab, Tooltip, Zoom } from '@mui/material';
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SavingsIcon from '@mui/icons-material/Savings';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import EnergySavingIcon from "assets/icons/energy_saving.png";
import CostSavingIcon from "assets/icons/cost_saving.png";
import FormControlLabel from "@mui/material/FormControlLabel"; 

import Stack from "@mui/material/Stack";
import { setService, resetService } from "rtkSlices/ServiceCreatorSlice";
import { useEditServiceMutation } from "services/services";
import { DatePicker } from "antd";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;
import { useLocation } from 'react-router-dom';

const StyledRangePicker = styled(RangePicker)`
  position: relative;
  top: -14px;
  overflow: hidden;
  width: 278px;

  .ant-picker-input {
    width: 100px!important;
  }

  .ant-picker-suffix, .ant-picker-separator {
    color: #000
  }
`;

export default function EnergySavingDisplay(props) {
  const dispatch = useDispatch();
  let token = window.localStorage.getItem("token");
  const metaDataValue = useSelector((state) => state.metaData);
  const serviceValue = useSelector((state) => state.serviceCreator);
  console.log("serviceValue: ", serviceValue);
  const filtersValue = useSelector((state) => state.filterDevice);
  const [datapoints, setDatapoints] = useState({});
  const kpiData = localStorage.getItem("kpiData")
  ? JSON.parse(localStorage.getItem("kpiData"))
  : [];
  const [selectedDatapoint, setSelectedDatapoint] = useState(
    checkForDatapoint() || null
  );
  const [energySavingFirstDatapoint, setEnergySavingFirstDatapoint] = useState("energyConsumptionBefore");
  const [energySavingFirstDatapointPrev, setEnergySavingFirstDatapointPrev] = useState("energyConsumptionBefore");
  const [energySavingSecondDatapoint, setEnergySavingSecondDatapoint] = useState("energyConsumptionAfter");
  const [energySavingSecondDatapointPrev, setEnergySavingSecondDatapointPrev] = useState("energyConsumptionAfter");
  const [energySavingThirdDatapoint, setEnergySavingThirdDatapoint] = useState("energySaved");
  const [energySavingThirdDatapointPrev, setEnergySavingThirdDatapointPrev] = useState("energySaved");
  console.log({ metaDataValue, filtersValue });
  const service = metaDataValue.services.find((s) => s.id == props.service);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawer, setDrawer] = useState(false);
  const [refetch, setRefetch] = useState(true);
  const [intervalRef, setIntervalRef] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openGraphDPPopup, setOpenGraphDPPopup] = useState(false);
  const [multiplier, setMultiplier] = useState(service.multiplier);
  const [multiplierPrev, setMultiplierPrev] = useState(service.multiplier);
  const [currency, setCurrency] = useState(service.currency);
  const [currencyPrev, setCurrencyPrev] = useState(service.currency);
  const [temperatureUnit, setTemperatureUnit] = useState(service.temperatureUnit || "celsius");
  const [temperatureUnitPrev, setTemperatureUnitPrev] = useState(service.temperatureUnit || "celsius");
  const [editService, editServiceResult] = useEditServiceMutation();
  const [dates, setDates] = React.useState(null);
  const [days, setDays] = React.useState(null);
  const dateFormat = "YYYY-MM-DD HH:mm";//hh is 12 hour,HH is 24
  const [endDate, setEndDate] = useState(dayjs().format(dateFormat));
  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day').format(dateFormat));
  const [selectedGraphDatapoints, setSelectedGraphDatapoints] = React.useState(['energyConsumptionBefore', 'energyConsumptionAfter']);
  const [netEnergySavingDatapoints, setNetEnergySavingDatapoints] = React.useState(['energySaved']);

  
  const [selectedGraphDatapointsFinal, setSelectedGraphDatapointsFinal] = useState(selectedGraphDatapoints);
  const [temperatureConversionSupportDatapoints, setTemperatureConversionSupportDatapoints] = React.useState(['outsideTemp', 'outsideHeatIndex', 'insideTemp', 'insideHeatIndex', 'differenceHI']);

  const [isSnackbarVisible,setSnackbarVisible]=React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedLabel, setSelectedLabel] = useState('');
  const [graphData, setGraphData] = useState([]);
  const [csvData, setcsvData] = useState([]);
  const [statsData, setStatsData] = useState({});
  const [isFetching, setIsFetching] = useState(true);
  const [energySavingFirstDatapointLoaded, setEnergySavingFirstDatapointLoaded] = useState(false);
  const [energySavingSecondDatapointLoaded, setEnergySavingSecondDatapointLoaded] = useState(false);


  console.log("selectedGraphDatapoints: ", selectedGraphDatapoints);
  console.log("selectedGraphDatapointsFinal: ", selectedGraphDatapointsFinal);
  
  const location = useLocation();
  const brandName = metaDataValue.branding?.name == "Rensair" ? `${metaDataValue.branding?.name} Cloud` : (metaDataValue.branding?.name ? metaDataValue.branding?.name : "Invixible");
  const pageName = `${service.name} | Energy Saving Display`;

  useEffect(() => {
    document.title =  `${brandName} | ${pageName}`;
  }, [props?.title, location]);

  const currencySymbol = {
    'USD': '$',
    'EUR': '€',
    'GBP': '£'
  };

  const temperatureUnitSymbol = {
    'celsius': '°C',
    'fahrenheit': '°F'
  };  

  const energySavingDatapoints = [
    {
      label: 'Temperature (Outside)', // 1
      value: 'outsideTemp',
      icon: EnergyOn,
      unit: temperatureUnitSymbol[temperatureUnitPrev]
    },
    {
      label: 'Heat Index (Outside)', // 2
      value: 'outsideHeatIndex',
      icon: EnergyOn,
      unit: temperatureUnitSymbol[temperatureUnitPrev]
    },
    {
      label: 'Temperature (Inside)', // 3
      value: 'insideTemp',
      icon: EnergyOn,
      unit: temperatureUnitSymbol[temperatureUnitPrev]
    },
    {
      label: 'Heat Index (Inside)', // 4
      value: 'insideHeatIndex',
      icon: EnergyOn,
      unit: temperatureUnitSymbol[temperatureUnitPrev]
    },
    {
      label: 'Heat index Difference', // 5
      value: 'differenceHI',
      icon: EnergyOn,
      unit: temperatureUnitSymbol[temperatureUnitPrev]
    },
    {
      label: 'Heating Coefficient', // 6
      value: 'heatingCoefficient',
      icon: EnergyOn,
      unit: ""
    },
    {
      label: 'Cooling Coefficient (COP)', // 7
      value: 'coolingCoefficient',
      icon: EnergyOn,
      unit: ""
    },
    {
      label: 'HVAC Fan Power',  // 8
      value: 'fanPower',
      icon: AirFlowRateIcon,
      unit: "kW"
    },
    {
      label: 'HVAC Airflow Rate',  // 9
      value: 'HVACAirFlowRate',
      icon: AirFlowRateIcon,
      unit: "m3/h"
    },
    {
      label: 'HVAC Airflow Rate Reduction', // 10
      value: 'HVACAirFlowRateReduction',
      icon: AirFlowRateIcon,
      unit: "%"
    },
    {
      label: 'HVAC Fan Energy Consumption', // 11
      value: 'newFanEnergyConsumption',
      icon: EnergyOn,
      unit: "kWh"
    },
    {
      label: 'Energy Cosumption With Rensair (Heating)', // 12
      value: 'heatEnergyConsumptionAfter',
      icon: EnergyOn,
      unit: "kWh"
    },
    {
      label: 'Energy Consumption W/O Rensair (Heating)', // 13
      value: 'heatEnergyConsumptionBefore',
      icon: EnergyOn,
      unit: "kWh"
    },
    {
      label: 'Energy Cosumption With Rensair (Cooling)', // 14
      value: 'coolingEnergyConsumptionAfter',
      icon: EnergyOn,
      unit: "kWh"
    },
    {
      label: 'Energy Consumption W/O Rensair (Cooling)', // 15
      value: 'coolingEnergyConsumptionBefore',
      icon: EnergyOn,
      unit: "kWh"
    },
    {
      label: 'Energy Cosumption With Rensair (Heating + Cooling)', // 16
      value: 'heatcoolEnergyConsumptionAfter',
      icon: EnergyOn,
      unit: "kWh"
    },
    {
      label: 'Energy Consumption W/O Rensair (Heating + Cooling)', // 17
      value: 'heatcoolEnergyConsumptionBefore',
      icon: EnergyOn,
      unit: "kWh"
    },
    {
      label: 'Air Purifier Energy Consumption', // 18
      value: 'airPurifierEnergyConsumption',
      icon: EnergyOn,
      unit: "kWh"
    },
    {
      label: 'Energy Consumption With Rensair (Total)', // 19
      value: 'energyConsumptionAfter',
      icon: EnergyOn,
      unit: "kWh"
    },
    {
      label: 'Energy Consumption W/O Rensair (Total)', // 20
      value: 'energyConsumptionBefore',
      icon: EnergyOn,
      unit: "kWh"
    },
    {
      label: 'Energy Saved', // 21
      value: 'energySaved',
      icon: EnergyOn,
      unit: "kWh"
    },
    {
      label: 'Cost With Rensair', // 22
      value: 'costAfterSaving',
      icon: DollarOn,
      unit: currencySymbol[currency]
    },
    {
      label: 'Cost W/O Rensair', // 23
      value: 'costBeforeSaving',
      icon: DollarOn,
      unit: currencySymbol[currency]
    },
    {
      label: 'Cost Saved', // 24
      value: 'savingCost',
      icon: DollarOn,
      unit: currencySymbol[currency]
    },
    {
      label: 'Accumulated Energy Saving', // 25
      value: 'accumulatedEnergySaving',
      icon: EnergyOn,
      unit: "kWh"
    },
    {
      label: 'Accumulated Cost Saving', // 26
      value: 'accumulatedCostSaving',
      icon: DollarOn,
      unit: currencySymbol[currency]
    }
  ];

  /* const energySavingAPIDatapoints = [
      'EnergyConsumptionBefore',
      'EnergyConsumptionAfter',
      'EnergySaved',
      'EnergySavedPercentage',
      'CostBeforeSaving',
      'CostAfterSaving',
      'SavingCost',
      'OutsideTemperature',
      'OutsideHeatIndex',
      'InsideTemperature',
      'InsideHeatIndex',
      'HIDifference',
      'HVACAirflowRate',
  ]; */

  const convertToCSV = (objArray) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '') line += ',';

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  };

  const downloadCSV = () => {
    const csvDataVal = new Blob([convertToCSV(csvData)], { type: 'text/csv' });
    const csvURL = URL.createObjectURL(csvDataVal);
    const link = document.createElement('a');
    link.href = csvURL;
    let fileName = "EnergySavingDisplay_CSV";
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const overrideCostDatapoint = (datapoint, flag) => {
    let overrideDatapointFound = false;
    if(datapoint == 'costBeforeSaving') {
      datapoint = 'energyConsumptionBefore';
      overrideDatapointFound = true;
    } else if (datapoint == "costAfterSaving") {
      datapoint = 'energyConsumptionAfter';
      overrideDatapointFound = true;
    } else if (datapoint == "savingCost" || datapoint == "accumulatedEnergySaving" || datapoint == "accumulatedCostSaving") {
      datapoint = "energySaved";
      overrideDatapointFound = true;
    }
    if(flag) {
      return overrideDatapointFound;
    } else {
      return datapoint;
    }
  }

  function showSnackbar(title, message, variant, timeOut) {
    return enqueueSnackbar(
      { title, message: message ? message : "Something went wrong", variant },
      { timeOut }
    );
  }

  const energySavingDatapointsMap = energySavingDatapoints.reduce((acc, item) => {
    acc[item.value] = item.icon;
    return acc;
  }, {});
  const energySavingDatapointsLabel = energySavingDatapoints.reduce((acc, item) => {
    acc[item.value] = item.label;
    return acc;
  }, {});
  const energySavingDatapointsUnit = energySavingDatapoints.reduce((acc, item) => {
    acc[item.value] = item.unit;
    return acc;
  }, {});
  console.log("energySavingDatapointsMap: ", energySavingDatapointsMap)


  const overriddenDatapoints = selectedGraphDatapointsFinal.map((e) => {
    const overriddenDatapoint = overrideCostDatapoint(e, false);
    return selectedGraphDatapointsFinal.includes(overriddenDatapoint) ? e : overriddenDatapoint;
  });

  const uniqueOverriddenDatapoints = Array.from(new Set(overriddenDatapoints));

  const analytics = useGetGroupAggregatedMultipleParameterReadingQuery({
    token,
    id: props.service,
    parameters: `?mode=hourly&parameter=${JSON.stringify(
      uniqueOverriddenDatapoints
    )}&dateFrom=${new Date(startDate).toISOString()}&dateTo=${new Date(endDate).toISOString()}${
      (service.group && service.group.id) ||
      (filtersValue.group && filtersValue.group.id)
        ? `&groupId=${service.group?.id || filtersValue.group?.id}`
        : ""
    }`,
  });

  function toFixedIfNecessary( value, dp ){
    if(value){
      return +parseFloat(value).toFixed( dp );
    } else {
      return undefined
    }
  }

  useEffect(() => {
    if (!analytics.isFetching && analytics.isSuccess) {
      
      console.log("analytics.isSuccess: ", JSON.parse(JSON.stringify(analytics)));
      if (
        analytics?.data.payload?.data &&
        Object.keys(analytics.data.payload.data).length
      ) {

        let formattedData = [];
        let formattedDataforCSV = [];
        Object.keys(analytics.data.payload.data).forEach((key) => {
          let nameWithUnit = ""
          let nameofDatapoint = ""
          let nameofUnit = ""
          nameofDatapoint = energySavingDatapointsLabel[key];

          let keyToUse = ""
          let applyMultiplierForItem = false;
          let applyTemperatureConversion = false;
          let executeNext = true;
          if ( key == "energyConsumptionBefore" && selectedGraphDatapointsFinal.includes("costBeforeSaving") ) {
            keyToUse = key;
            nameofDatapoint = energySavingDatapointsLabel['costBeforeSaving'];
            nameofUnit = energySavingDatapointsUnit['costBeforeSaving'];
            applyMultiplierForItem = true;
            if(!selectedGraphDatapointsFinal.includes("energyConsumptionBefore")) {
              executeNext = false;
            }
          } else if( key == "energyConsumptionAfter" && selectedGraphDatapointsFinal.includes("costAfterSaving") ) {
            keyToUse = key;
            nameofDatapoint = energySavingDatapointsLabel['costAfterSaving'];
            nameofUnit = energySavingDatapointsUnit['costAfterSaving']
            applyMultiplierForItem = true;
            if(!selectedGraphDatapointsFinal.includes("energyConsumptionAfter")) {
              executeNext = false;
            }
          } else if( key == "energySaved" ) {
            console.log("keyenergySaved: ", key);
            const energySavedDatapoints = ["savingCost", "accumulatedEnergySaving", "accumulatedCostSaving"];
            energySavedDatapoints.forEach((datapoint) => {
              if(selectedGraphDatapointsFinal.includes(datapoint)){
                keyToUse = key;
                nameofDatapoint = energySavingDatapointsLabel[datapoint];

                if (keyToUse != "") {
                  let readings = analytics.data.payload.data[keyToUse].readings.map((reading) => {
                    const value = toFixedIfNecessary(reading.reading, 1);
                    let adjustedValue = value;
                    if(datapoint == "savingCost") {
                      
                      adjustedValue = adjustedValue * multiplier;
                      formattedDataforCSV.push({
                        name: nameofDatapoint,
                        date: new Date(reading.date).toISOString(),
                        value: toFixedIfNecessary(adjustedValue, 2)
                      });
                      return {
                        date: new Date(reading.date).getTime(),
                        value: toFixedIfNecessary(adjustedValue, 2)
                      }
                    }
                    
                  })

                  if (datapoint === "accumulatedEnergySaving" || datapoint === "accumulatedCostSaving") {
                    let readingsSaving = analytics.data.payload.data[key].readings;
                    let accumulatedValues;
                    if(datapoint === "accumulatedEnergySaving") {
                      accumulatedValues = readingsSaving.reduce((acc, current, index) => {
                        const sum = (index === 0 ? 0 : acc[index]) + current.reading;
                        acc.push(sum);
                        return acc;
                      }, [0]);
                    } else if(datapoint === "accumulatedCostSaving") {
                      accumulatedValues = readingsSaving.reduce((acc, current, index) => {
                        const sum = (index === 0 ? 0 : acc[index]) + current.reading * multiplier;
                        acc.push(sum);
                        return acc;
                      }, [0]);          
                    }
                    nameWithUnit = readings?.length
                      ? nameofDatapoint + (energySavingDatapointsUnit[datapoint] ? ` - ${energySavingDatapointsUnit[datapoint]}` : '')
                      : nameofDatapoint + " (No Data)";
                    formattedData.push({
                      name: nameWithUnit,
                      readings: accumulatedValues.map((value, index) => ({
                        date: readingsSaving[index] ? new Date(readingsSaving[index].date).getTime() : null,
                        value: value ? toFixedIfNecessary(value, 2) : 0
                      })),
                      unit: energySavingDatapointsUnit[datapoint],
                    });

                  }else {
                    nameWithUnit = readings?.length
                      ? nameofDatapoint + (energySavingDatapointsUnit[datapoint] ? ` - ${energySavingDatapointsUnit[datapoint]}` : '')
                      : nameofDatapoint + " (No Data)";
                    formattedData.push({
                      name: nameWithUnit,
                      readings,
                      unit: energySavingDatapointsUnit[datapoint]
                    })
                  }
        
                }
              }
              

            })
            if(!selectedGraphDatapointsFinal.includes("energySaved")) {
              executeNext = false;
            }
          }  else if( temperatureConversionSupportDatapoints.includes(key) && selectedGraphDatapointsFinal.includes(key) ) {
            keyToUse = key;
            nameofDatapoint = energySavingDatapointsLabel[key];
            nameofUnit = energySavingDatapointsUnit[key]
            applyTemperatureConversion = true;
            if(!selectedGraphDatapointsFinal.includes(key)) {
              executeNext = false;
            }
          }
          if (keyToUse != "" && applyMultiplierForItem) {
            let readings = analytics.data.payload.data[keyToUse].readings.map((reading) => {
              const value = toFixedIfNecessary(reading.reading, 1);
              let adjustedValue = value * multiplier;
              formattedDataforCSV.push({
                name: nameofDatapoint,
                date: new Date(reading.date).toISOString(),
                value: toFixedIfNecessary(adjustedValue, 2)
              });
              if(temperatureConversionSupportDatapoints.includes(keyToUse) && temperatureUnitPrev == "fahrenheit"){
                adjustedValue = (adjustedValue * 9/5) + 32;
              }
              return {
                date: new Date(reading.date).getTime(),
                value: toFixedIfNecessary(adjustedValue, 2)
              }
            })
            nameWithUnit = readings?.length
            ? nameofDatapoint + (nameofUnit ? ` - ${nameofUnit}` : '')
            : nameofDatapoint + " (No Data)";
            formattedData.push({
              name: nameWithUnit,
              readings,
              unit: nameofUnit
            })
          }

          if(executeNext) {
            nameofDatapoint = energySavingDatapointsLabel[key];
            let readings = analytics.data.payload.data[key].readings.map((reading) => {
              let value = toFixedIfNecessary(reading.reading, 1);
              formattedDataforCSV.push({
                name: nameofDatapoint,
                date: new Date(reading.date).toISOString(),
                value: toFixedIfNecessary(reading.reading, 2)
              })
              if(temperatureConversionSupportDatapoints.includes(keyToUse) && temperatureUnitPrev == "fahrenheit"){
                value = (value * 9/5) + 32;
              }
              return {
                date: new Date(reading.date).getTime(),
                value: toFixedIfNecessary(value, 2)
              }
            })
            
            nameWithUnit = readings?.length
            ? nameofDatapoint + (energySavingDatapointsUnit[key] ? ` - ${energySavingDatapointsUnit[key]}` : '')
            : nameofDatapoint + " (No Data)";
            formattedData.push({
              name: nameWithUnit,
              readings,
              unit: energySavingDatapointsUnit[key]
            })
            
          }
          
        })
        console.log("formattedDataforCSV: ", formattedDataforCSV);
        console.log("formattedData: ", formattedData);

        setGraphData(formattedData);
        setcsvData(formattedDataforCSV)
        setIsFetching(false);
      } else {
        setGraphData([]);
        setcsvData([])
        setIsFetching(false);
      }
    }
  }, [analytics.isFetching, selectedGraphDatapointsFinal, multiplier]);

  const piStatsData = useGetGroupAggregatedReadingQuery({
    token,
    id: props.service,
    parameters: `?mode=hourly&parameter=${JSON.stringify(
      netEnergySavingDatapoints.map((e) => e)
    )}&dateFrom=${new Date(startDate).toISOString()}&dateTo=${new Date(endDate).toISOString()}${
      (service.group && service.group.id) ||
      (filtersValue.group && filtersValue.group.id)
        ? `&groupId=${service.group?.id || filtersValue.group?.id}`
        : ""
    }`,
  });

  useEffect(() => {
    if (!piStatsData.isFetching && piStatsData.isSuccess) {
      console.log("piStatsData.isSuccess: ", JSON.parse(JSON.stringify(piStatsData)));
      if (
        piStatsData?.data?.payload &&
        Object.keys(piStatsData.data.payload).length
      ) {
        setStatsData(piStatsData?.data?.payload?.data[0]);
        console.log('statsData useeffect: ', statsData);
      } else {
        setStatsData({});
      }
    }
  }, [piStatsData.isFetching]);

  useEffect(() => {
    console.log({ filtersValue });
    if (filtersValue.group.id) {
      if (kpiData.length) {
        const ind = kpiData.findIndex((k) => k.serviceId == props.service);
        if (ind != -1) {
          const tempKpiData = [...kpiData];
          tempKpiData[ind] = { ...tempKpiData[ind], filter: filtersValue };
          localStorage.setItem("kpiData", JSON.stringify(tempKpiData));
          dispatch(setFilter(filtersValue));
        }
      }
    } else {
      const selectedKpiData =
        kpiData.length && kpiData.find((k) => k.serviceId == props.service);
      if (selectedKpiData && !selectedKpiData.filter) {
        const ind = kpiData.findIndex((k) => k.serviceId == props.service);
        if (ind != -1) {
          const tempKpiData = [...kpiData];
          delete tempKpiData[ind].filter;
          localStorage.setItem("kpiData", JSON.stringify(tempKpiData));
        }
      }
    }
  }, [JSON.stringify(filtersValue)]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGraphDatapointChange = (value) => () => {
    const currentIndex = selectedGraphDatapoints.indexOf(value);
    const newSelectedDatapoints = [...selectedGraphDatapoints];

    if (currentIndex === -1) {
      newSelectedDatapoints.push(value);
    } else {
      newSelectedDatapoints.splice(currentIndex, 1);
    }

    setSelectedGraphDatapoints(newSelectedDatapoints);
  };

  const handleDatapoint = (event) => {

    const {
      target: { value },
    } = event;
    console.log("handleDatapoint value: ", value);

    if (value.length > 0) {
      console.log("handleDatapoint selectedGraphDatapoints: ", selectedGraphDatapoints);

      const currentIndex = selectedGraphDatapoints.indexOf(value);
    const newSelectedDatapoints = [...selectedGraphDatapoints];

    if (currentIndex === -1) {
      newSelectedDatapoints.push(value);
    } else {
      newSelectedDatapoints.splice(currentIndex, 1);
    }

    setSelectedGraphDatapoints(typeof value === "string" ? value.split(",") : value);

      // setSelectedDatapoint(typeof value === "string" ? value.split(",") : value)
      /* setPage(1);
      updateColumns(typeof value === "string" ? value.split(",") : value);
      setDatapointName(typeof value === "string" ? value.split(",") : value); */
    } else {
      showSnackbar(
        "Energy Saving Display",
        "Atleast one datapoint is required",
        "info",
        1000
      );
    }
  };

  const graphSelectedDataPointAfterSelection = () => {
    setSelectedGraphDatapointsFinal(selectedGraphDatapoints);
  }

  const resetToLastSavedValues = () => {
    setSelectedGraphDatapoints(selectedGraphDatapointsFinal);
  }

  const resetToLastSavedValuesMainPopup = () => {
    setCurrencyPrev(currency);
    setMultiplierPrev(multiplier);
    setEnergySavingFirstDatapointPrev(energySavingFirstDatapoint);
    setEnergySavingSecondDatapointPrev(energySavingSecondDatapoint);
    setEnergySavingThirdDatapointPrev(energySavingThirdDatapoint);
    setTemperatureUnitPrev(temperatureUnit);
  }

  const validateGraphDatapoints = () => {
    if(selectedGraphDatapoints.length < 1) {
      const newSelectedDatapoints = [...selectedGraphDatapoints];
      newSelectedDatapoints.push('EnergyConsumptionBefore');
      setSelectedGraphDatapoints(newSelectedDatapoints);
    }
    /* if(selectedGraphDatapoints.length < 2) {
      showSnackbar(
        "Warning",
        "Atlease 2 datapoints are required to select",
        "warning",
        1000
      );
    } */
  }

  function checkForDatapoint() {
    const selectedKpiData =
      kpiData.length && kpiData.find((k) => k.serviceId == props.service);
    return selectedKpiData && selectedKpiData.customDatapoint
      ? selectedKpiData.customDatapoint
      : "";
  }

  useEffect(() => {
    const ind = kpiData.findIndex((k) => k.serviceId == props.service);
    if (ind == -1) {
      kpiData.push({
        energySavingAQIPopup: true,
        serviceId: props.service,
      });
      localStorage.setItem("kpiData", JSON.stringify(kpiData));
    } else {
      const tempKpiData = [...kpiData];
      tempKpiData[ind] = { ...tempKpiData[ind], energySavingAQIPopup: true };
      localStorage.setItem("kpiData", JSON.stringify(tempKpiData));
    }
    if (kpiData.length) {
      const selectedKpiData = kpiData.find((k) => k.serviceId == props.service);
      if (selectedKpiData.filter) {
        dispatch(setFilter(selectedKpiData.filter));
      }
    }
    /* const timerRef = setInterval(() => {
      analytics.refetch();
      piStatsData.refetch();
    }, 60000);
    setIntervalRef(timerRef); */
    if (!props.layout) {
      if (service.group && service.group?.id) {
        dispatch(setFilter({ group: service.group }));
      } else {
        dispatch(setFilter({ group: { name: "All assets", id: "" } }));
      }
    }
    return () => {
      clearInterval(intervalRef);
    };
  }, []);

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  const toggleDrawer = () => {
    setDrawer(!drawer);
  };

  const handleMultiplierChange = (e) => {
    const inputValue = e.target.value;
    // Regular expression to match integers and floats
    const isValidInput = /^-?\d*\.?\d*$/.test(inputValue);
    if (isValidInput || inputValue === "") {
      setMultiplierPrev(inputValue);
    }
  };

  const handleTemperatureUnitChange = (e) => {
    const inputValue = e.target.value;
    setTemperatureUnitPrev(inputValue);
  };


  async function handleSave() {
    
    setEnergySavingFirstDatapoint(energySavingFirstDatapointPrev);
    setEnergySavingSecondDatapoint(energySavingSecondDatapointPrev);
    setEnergySavingThirdDatapoint(energySavingThirdDatapointPrev);
  console.log("handleSave triggerd");
  console.log({service})
    if(service.currency !== currencyPrev || service.multiplier !== multiplierPrev || service.temperatureUnit !== temperatureUnitPrev){
      let body = {
        currency: currencyPrev,
        multiplier: multiplierPrev,
        temperatureUnit: temperatureUnitPrev,
      };
      console.log("BODY CHANGED", body)
      // setCurrency(currencyPrev);
      // setMultiplier(multiplierPrev);  
      // dispatch(
      //   setService({
      //     ...body
      //   })
      // );
      await editService({
        token,
        id: service.id,
        body,
      });
      window.location.reload()
    }
  

  }

  function calcDiffDays(dates){
    const date1 = new Date(dates[0].slice(0,10));
    const date2 = new Date(dates[1].slice(0,10));
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    return diffDays || 1;
  }

  const handleDateChange = (value, dates) => {
    let start, end;
    if (value) {

      start = value[0].toDate();
      end = value[1].toDate();
      start.setSeconds(0,0)
      end.setSeconds(0,0)
      if(Math.abs(dayjs(start).diff(dayjs(end),'day'))>30){
        if(!isSnackbarVisible){
        showSnackbar(
          "Error",
          "more than 30 days were selected",
          "error",
          1000
        );
        setSnackbarVisible(true)
        setTimeout(()=>setSnackbarVisible(false),1000)
        return
      }
      }
      setDays(calcDiffDays(dates))
    } 
    else {
      start = new Date(new Date().setHours(0, 0, 0, 0));
      end = new Date();
      if(Math.abs(dayjs(start).diff(dayjs(end),'day'))>30){
        if(!isSnackbarVisible){
        showSnackbar(
          "Error",
          "more than 30 days were selected",
          "error",
          1000
        );
        setSnackbarVisible(true)
        setTimeout(()=>setSnackbarVisible(false),1000)
        return
      }
      }
      setDays(1)
    }
    
    setStartDate(start);
    setEndDate(end);

    setDates([start,end])
     // Find and set the selected preset label
     const selectedPreset = rangePresets.find(preset => 
      dayjs(start).isSame(preset.value[0], 'minute') && dayjs(end).isSame(preset.value[1], 'minute')
    );
    setSelectedLabel(selectedPreset ? selectedPreset.label : '');
  };

  const onOpenChange = (open) => {
    if (open) {
      console.log("setting dates to null")
      setDates([null, null]);
    } else {
      console.log("setting dates to null")
      setDates(null);
    }
  };

  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }

    if(dates[0]){
      if(current.diff(dates[0],'day')>30){
        return true
      }
      else{
        return false
      }
    }
    if(dates[1]){
      if(current.diff(dates[1],'day')>30){
        return true
      }
      else{
        return false
      }
    }
  };

  const formatNumber = (num) => {
    if(num){
      return num && Number.isInteger(num) ? num : num?.toFixed(1);
    }else {
      return "-";
    }
  };
  

  const rangePresets = [
    {
      label: "Last 12 hours",
      value: [dayjs().subtract(12, "h"), dayjs()],
    },
    {
      label: "Last 24 hours",
      value: [dayjs().add(-1, "d"), dayjs()],
    },
    {
      label: "Today",
      value: [dayjs().startOf("day"), dayjs()],
    },
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 14 Days",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
  ];

  return (
    <Fragment>
      <>
        <span>
          <CloseIcon
            sx={{
              position: "absolute",
              right: "10px",
              top: "12px",
              cursor: "pointer",
              color: "lightgrey",
            }}
            onClick={() => {

              const ind = kpiData.findIndex(
                (k) => k.serviceId == props.service
              );
              if (ind != -1) {
                const tempKpiData = [...kpiData];
                tempKpiData[ind] = {
                  ...tempKpiData[ind],
                  energySavingAQIPopup: false,
                  serviceDashboard: true,
                };
                localStorage.setItem("kpiData", JSON.stringify(tempKpiData));
              }
              props.history.push(`/solutions/${props.service}`);
            }}
          />
        </span>

        {!analytics.isError && graphData.length && analytics.isSuccess && !isFetching  ?
              <div
              style={{
                margin: "3px 10px 0 0",
              }}
            >
    
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                style={{ display: 'flex', flexWrap: 'wrap' }}
              >
                <Grid item xs={6} md={6} style={{ textAlign: 'left' }}>
                  <div
                    style={{
                      marginBottom: "10px",
                      width: "100%",
                      fontSize: "18px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      dispatch(setFilter({ view: "2", open: true }));
                      toggleDrawer();
                    }}
                  >
                    Hello {filtersValue.group?.id ? `[${filtersValue.group.name}]` : ""}
                  </div>
                </Grid>
                <Grid item xs={6} md={6} style={{ textAlign: 'right' }}>
                  <div
                    style={{
                      marginBottom: "10px",
                      width: "100%",
                      fontSize: "18px",
                    }}
                  >
                    <span style={{fontSize: "13px", marginRight: "50px", fontWeight: "bold"}}>
                      {/* {selectedLabel} */}
                      </span><br></br>
                    
                    <span style={{fontSize: "15px", marginRight: "11px", fontWeight: "400", position: "relative", top: "-14px", color: "#000"}}>Date range</span>
                    <StyledRangePicker
                      onChange={(value, dateString) => {
                        handleDateChange(value, dateString);
                      }}
                      defaultValue={[
                        dayjs(dayjs(startDate), dateFormat),
                        dayjs(dayjs(endDate), dateFormat),
                      ]}
                      value={[
                        dayjs(dayjs(startDate), dateFormat),
                        dayjs(dayjs(endDate), dateFormat),
                      ]}
                      showTime
                      format={dateFormat}
                      disabledDate={disabledDate}
                      onOpenChange={onOpenChange}
                      onCalendarChange={(val) => {
                        setDates(val)
                      }}
                      changeOnBlur
                      presets={rangePresets}
                      size="small"
                      // style={{ width: "14.5vw", position: "relative", top: "-14px", overflow: "hidden" }}
                    />
                    
                    <SettingsIcon 
                      fontSize="large"
                      style={{ cursor: "pointer", marginLeft: "13px", position: "relative", top: "-2px", color: "#78B9EB", width: "23px", color: "rgb(151, 151, 151)", opacity: "0.5" }}
                      onClick={() => {
                        setOpenPopup(true);
                      }}
                    />
                  </div>
                  
                </Grid>
              </Grid>
              
              <Box sx={{ flexGrow: 1, marginBottom: "-24px" }}>
                <Grid 
                  container
                  spacing={3}
                  style={{ display: "flex"}}
                >

                  <Grid
                  item
                  xs={6}
                  md={3}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "12px",
                    marginBottom: { xs: 10, md: 0 },
                    height: "100%",
                  }}
                  order={{ xs: 1, md: 0 }}
                  >
                  <div
                    style={{
                      flex: 1,
                      backgroundColor: "#F5F5F5",
                      width: "100%",
                      // height: "100%",
                      padding: "14px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "12px"
                    }}
                  >
                    <Stack
                      direction="column"
                      spacing={2}
                      style={{
                        // marginTop: "1.5%",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                        }}
                        className="energy-saving-div"
                      >
                        <div
                          className="energy-saving-unit"
                          style={{
                            color: "#000",
                            fontWeight: "400",
                            // opacity: "0.5",
                            fontSize: "20px",
                            letterSpacing: "1px"
                          }}
                        >
                          Net Energy Savings
                        </div>
                        <div
                          className="energy-saving-unit"
                          style={{
                            color: "#000",
                            fontWeight: "400",
                            // opacity: "0.5",
                            fontSize: "14px",
                            letterSpacing: "1px",
                            marginTop: "4px"
                          }}
                        >
                          [For The Selected Date Range]
                        </div>
                      </div>
                      <img
                        src={EnergySavingIcon}
                        style={{ width: "60px", height: "71px", marginTop: "6px", marginBottom: "-5px" }}
                      />
                      {/* <EnergySavingIcon 
                          fontSize="large"
                          style={{ width: "40px", height: "40px", color: "#F9D11D" }}
                        /> */}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="energy-saving-value"
                          style={{
                            fontSize: "25px",
                            color: "#000",
                            fontWeight: "bold",
                          }}
                        >
                          {formatNumber(statsData?.averageReading)}
                          <span
                            style={{
                              fontSize: "17px",
                              color: "#000",
                              fontWeight: "400",
                              marginLeft: "3px",
                            }}
                          >
                            {statsData?.unit}
                          </span>
                        </div>
                        <div
                          className="energy-saving-unit"
                          style={{
                            color: "#000",
                            // fontWeight: "400",
                            // opacity: "0.5",
                            fontSize: "14px",
                          }}
                        >
                          Saved
                        </div>
                      </div>
                    </Stack>
                  </div>
                  </Grid>


                  <Grid
                    item
                    xs={6}
                    md={3}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "12px",
                      marginBottom: { xs: 10, md: 0 },
                      height: "100%",
                    }}
                    order={{ xs: 1, md: 0 }}
                  >
                    <div
                      style={{
                        flex: 1,
                        backgroundColor: "#F5F5F5",
                        width: "100%",
                        // height: "100%",
                        padding: "14px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "12px"
                      }}
                    >
                      <Stack
                        direction="column"
                        spacing={2}
                        style={{
                          // marginTop: "1.5%",
                          textAlign: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                          }}
                          className="cost-saving-div"
                        >
                          <div
                            className="energy-saving-unit"
                            style={{
                              color: "#000",
                              fontWeight: "400",
                              // opacity: "0.5",
                              fontSize: "20px",
                              letterSpacing: "1px"
                            }}
                          >
                            Net Currency Savings
                          </div>
                          <div
                            className="energy-saving-unit"
                            style={{
                              color: "#000",
                              fontWeight: "400",
                              // opacity: "0.5",
                              fontSize: "14px",
                              letterSpacing: "1px",
                              marginTop: "4px"
                            }}
                          >
                            [For The Selected Date Range]
                          </div>
                        </div>
                        <img
                        src={CostSavingIcon}
                        style={{ width: "76px", height: "67px", marginTop: "6px", marginBottom: "-5px" }}
                      />
                       {/*  <EnergySavingIcon 
                            fontSize="large"
                            style={{ width: "40px", height: "40px" }}
                          /> */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="cost-saving-value"
                            style={{
                              fontSize: "25px",
                              color: "#000",
                              fontWeight: "bold",
                            }}
                          >
                            {currency == "GBP" ? "£" : currency == "EUR" ? "€" : "$"}
                            {formatNumber(multiplier * (statsData?.averageReading))}
                            <span
                              style={{
                                fontSize: "17px",
                                color: "#000",
                                fontWeight: "400",
                                marginLeft: "3px",
                              }}
                            >
                              {currency}
                            </span>
                          </div>
                          <div
                            className="cost-saving-unit"
                            style={{
                              color: "#000",
                              // fontWeight: "600",
                              // opacity: "0.5",
                              fontSize: "14px",
                            }}
                          >
                            Saved
                          </div>
                        </div>
                      </Stack>
                    </div>
                  </Grid>

    
                  {/* <Grid
                    item
                    md={1}
                    ></Grid> */}
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ 
                      display: "flex",
                      flexDirection: "row",
                      borderRadius: "12px",
                      marginBottom: { xs: 10, md: 0 },
                      // marginTop: "24px",
                      height: "100%",
                      // paddingRight: "40px"
                      // padding: "20px"
                      // marginRight: { xs: 0, md: 20 }
                  }}
                    order={{ xs: 1, md: 0 }}
                  >
                    <div style={{ 
                      // flex: 1,
                      backgroundColor: "#F5F5F5",
                      width: "100%",
                      minHeight: "217px",
                      padding: "14px 12px 4px 12px",
                      borderRadius: "12px"
                    }}>
                      <div style={{
                        textAlign: "center",
                        marginBottom: "19px",
                        color: "#000",
                        fontWeight: "400",
                        // opacity: "0.5",
                        fontSize: "20px",
                        letterSpacing: "1px"
                        }}>
                        Energy Consumption
                      </div>
                      <ESDDataPointTrend 
                          energySavingFirstDatapointLoaded={energySavingFirstDatapointLoaded}
                          setEnergySavingFirstDatapointLoaded={setEnergySavingFirstDatapointLoaded}
                          datapoint={overrideCostDatapoint(energySavingFirstDatapoint, false)}
                          actualDataPoint={energySavingFirstDatapoint}
                          label="Without Rensair"
                          color="#EFA115"
                          name="First Datapoint"
                          icon={energySavingDatapointsMap[energySavingFirstDatapoint]}
                          filtersValue={filtersValue}
                          id={props.service}
                          service={service}
                          startDate={startDate}
                          endDate={endDate}
                          multiplier={multiplier}
                          applyMultiplier={overrideCostDatapoint(energySavingFirstDatapoint, true)}
                          currency={currency}
                        />

                        <ESDDataPointTrend
                          energySavingSecondDatapointLoaded={energySavingSecondDatapointLoaded}
                          setEnergySavingSecondDatapointLoaded={setEnergySavingSecondDatapointLoaded}
                          datapoint={overrideCostDatapoint(energySavingSecondDatapoint, false)}
                          actualDataPoint={energySavingSecondDatapoint}
                          label="With Rensair"
                          color="#3399FF"
                          name="Second Datapoint"
                          icon={energySavingDatapointsMap[energySavingSecondDatapoint]}
                          filtersValue={filtersValue}
                          id={props.service}
                          service={service}
                          startDate={startDate}
                          endDate={endDate}
                          multiplier={multiplier}
                          applyMultiplier={overrideCostDatapoint(energySavingSecondDatapoint, true)}
                          currency={currency}
                        />
                      </div>
                  </Grid>
    
                </Grid>
              </Box>
    
              <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "50px",
                  height: "54vh",
                  position: "relative"
                }}
              >
                
                {/* <Grid item xs={12}> */}
    
                {!analytics.isFetching && analytics.isSuccess ? 
                  !analytics.isError && graphData.length && !isFetching ?
                    (<ESDChart
                      graphData={graphData}
                    />) : !analytics?.data?.success ? (
                      <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <img src={noData} height="80px" width="80px" />
                      <p style={{ color: "#c7c7c7", marginBottom: "15px" }}>
                        No data found
                      </p>
                    </div>
                    ) : null :
                  (
                    <Loader />
                  )
                }
                {/* </Grid> */}
                <Box
              sx={{
                position: 'absolute',
                top: 100,
                right: 10,
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
              }}
            >

            {/*   <Tooltip
              title={"Download CSV"}
              placement='left'
              arrow
              TransitionComponent={Zoom}
            ><IconButton
                sx={{
                  width:  33,
                  height: 33,
                  borderRadius: '50%',
                  backgroundColor: 'primary.main',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  }
                }}
                onClick={() => {
                  downloadCSV();
                }}
              >
                <DownloadIcon />
              </IconButton>
              </Tooltip> */}
              
            </Box>
                </Grid>
                </Box>
    
              <Grid container style={{ alignItems: "end" }}>
                <Grid item md={6} sm={12}>
                  <div
                    style={{
                      display: "flex",
                      textAlign: "start",
                      // marginTop: "1rem",
                    }}
                  >
                    
                  </div>
                </Grid>
                <Grid item md={6} sm={12}>
                  <div style={{position: "relative"}}>
                    
                  </div>
                </Grid>
              </Grid>
            </div> : !analytics.isFetching  && (!analytics?.data?.success || !graphData.length) ? (
              <div
              style={{
                margin: "3px 10px 0 0",
              }}
            >
    
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                style={{ display: 'flex', flexWrap: 'wrap' }}
              >
                <Grid item xs={6} md={6} style={{ textAlign: 'left' }}>
                  <div
                    style={{
                      marginBottom: "10px",
                      width: "100%",
                      fontSize: "18px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      dispatch(setFilter({ view: "2", open: true }));
                      toggleDrawer();
                    }}
                  >
                    Hello {filtersValue.group?.id ? `[${filtersValue.group.name}]` : ""}
                  </div>
                </Grid>
                <Grid item xs={6} md={6} style={{ textAlign: 'right' }}>
                  <div
                    style={{
                      marginBottom: "10px",
                      width: "100%",
                      fontSize: "18px",
                    }}
                  >
                    <span style={{fontSize: "13px", marginRight: "50px", fontWeight: "bold"}}>{selectedLabel}</span><br></br>
                    
                    <span style={{fontSize: "15px", marginRight: "11px", fontWeight: "400", position: "relative", top: "-14px", color: "#000"}}>Date range</span>
                    <StyledRangePicker
                      onChange={(value, dateString) => {
                        handleDateChange(value, dateString);
                      }}
                      defaultValue={[
                        dayjs(dayjs(startDate), dateFormat),
                        dayjs(dayjs(endDate), dateFormat),
                      ]}
                      value={[
                        dayjs(dayjs(startDate), dateFormat),
                        dayjs(dayjs(endDate), dateFormat),
                      ]}
                      showTime
                      format={dateFormat}
                      disabledDate={disabledDate}
                      onOpenChange={onOpenChange}
                      onCalendarChange={(val) => {
                        setDates(val)
                      }}
                      changeOnBlur
                      presets={rangePresets}
                      size="small"
                      // style={{ width: "14.5vw", position: "relative", top: "-14px", overflow: "hidden" }}
                    />
                    
                    <SettingsIcon 
                      fontSize="large"
                      style={{ cursor: "pointer", marginLeft: "13px", position: "relative", top: "-2px", color: "#78B9EB", width: "23px", color: "rgb(151, 151, 151)", opacity: "0.5" }}
                      onClick={() => {
                        setOpenPopup(true);
                      }}
                    />
                  </div>
                  
                </Grid>
              </Grid>
                  <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "77%",
                    position: "absolute",
                    left: "42%"
                  }}
                >
                  <img src={noData} height="80px" width="80px" />
                  <p style={{ color: "#c7c7c7", marginBottom: "15px" }}>
                    No data found. Change filter values to get data.
                  </p>
                </div>
                </div>
                ) : null
            }
        
      </>


      <Dialog open={openGraphDPPopup} aria-labelledby="form-dialog-title" PaperProps={{ style: { minWidth: 400 } }} >
        <DialogTitle>Change Parameters</DialogTitle>
        <DialogContent>
        <div>

      <Box py={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{paddingTop: "0px"}}>
          <List style={{paddingTop: "0px"}}>
            {energySavingDatapoints.map((datapoint, index) => (
              <ListItem key={index} button onClick={handleGraphDatapointChange(datapoint.value)} style={{ padding: '0px 0px' }}>
                <Checkbox
                  checked={selectedGraphDatapoints.indexOf(datapoint.value) > -1}
                />
                <ListItemText primary={datapoint.label} />
              </ListItem>
            ))}
          </List>
          </Grid>
        </Grid>
      </Box>

    </div>
        </DialogContent>
        <DialogActions>

        <div>
          <Button onClick={() => {
            setOpenGraphDPPopup(false);
            resetToLastSavedValues();
            // setAirQualityEnergyPopup(false)
            }} color="error">
            Cancel
          </Button>
          <Button onClick={() => {
            setOpenGraphDPPopup(false);
            validateGraphDatapoints();
            graphSelectedDataPointAfterSelection();
            // submitEnergySavingConfigs();
            // handleSave();
            // setSelectedLayout(1);
          }} color="secondary">
            Submit
          </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog open={openPopup} aria-labelledby="form-dialog-title" PaperProps={{ style: { minWidth: 400 } }} >
        <DialogTitle>Change Parameters</DialogTitle>
        <DialogContent>
        <div>

      <Box py={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Traiff"
              value={multiplierPrev}
              onChange={handleMultiplierChange}
            />
          </Grid>
        </Grid>
      </Box>
      <Box py={2} style={{paddingTop: "0"}}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
          <InputLabel>Currency</InputLabel>
            <Select
            fullWidth
            label="Currency"
            name="currency"
            value={currencyPrev}
            onChange={(e) => {
              setCurrencyPrev(e.target.value)
            }}
          >
            <MenuItem value="USD">USD</MenuItem>
            <MenuItem value="EUR">EUR</MenuItem>
            <MenuItem value="GBP">GBP</MenuItem>
          </Select>
          </Grid>
        </Grid>
      </Box>

      <Box py={2} style={{paddingTop: "0"}}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
          <InputLabel>Temperature unit</InputLabel>
            <Select
              fullWidth
              label="Temperature unit"
              name="temperature_unit"
              value={temperatureUnitPrev}
              onChange={handleTemperatureUnitChange}
            >
              <MenuItem value="celsius">Celsius (°C)</MenuItem>
              <MenuItem value="fahrenheit">Fahrenheit (°F)</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Box>
      <Divider />

      <Box py={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{marginTop: "0px", marginLeft: "-8px", marginRight: "-7px"}}>
          {/* <List style={{paddingTop: "0px"}}>
            {energySavingDatapoints.map((datapoint, index) => (
              <ListItem key={index} button onClick={handleGraphDatapointChange(datapoint.value)} style={{ padding: '0px 0px' }}>
                <Checkbox
                  checked={selectedGraphDatapoints.indexOf(datapoint.value) > -1}
                />
                <ListItemText primary={datapoint.label} />
              </ListItem>
            ))}
          </List> */}

          <FormControl sx={{ m: 1, width: 352 }}>
          <InputLabel id="demo-multiple-checkbox-label">Datapoints</InputLabel>
          {console.log("demo-multiple-checkbox-label selectedGraphDatapoints: ", selectedGraphDatapoints)}
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={selectedGraphDatapoints}
            onChange={handleDatapoint}
            input={<OutlinedInput label="Datapoints" />}
            renderValue={(selected) => {
              {console.log("demo-multiple-checkbox-label selected: ", selected)}

             let returnArray = ""
             selected.forEach((value, i, arr) => {
              const found = energySavingDatapoints.find((sensor) => sensor.value == value)
              {console.log("demo-multiple-checkbox-label found: ", found)}

              if(found){
                if(i == arr.length-1){
                  returnArray += found.label
                } else {
                  returnArray += `${found.label}, `
                }
              }
             })
             return returnArray 
            }}
          >
                      {console.log("demo-multiple-checkbox-label energySavingDatapoints: ", energySavingDatapoints)}

            {energySavingDatapoints.map((elm) => (
              <MenuItem key={elm.value} value={elm.value}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <ListItemText primary={elm.label} />
                  {selectedGraphDatapoints.indexOf(elm.value) > -1 ? (
                    <CheckCircleIcon
                      color="secondary"
                      style={{ height: "15px", width: "15px", margin: "10px" }}
                    />
                  ) : null}
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

          </Grid>
        </Grid>
      </Box>


    </div>
        </DialogContent>
        <DialogActions>

        <div>
          <Button onClick={() => {
            setOpenPopup(false);
            resetToLastSavedValuesMainPopup();
            }} color="error">
            Cancel
          </Button>
          <Button onClick={() => {
            setOpenPopup(false);
            handleSave();
            graphSelectedDataPointAfterSelection();
            // setSelectedLayout(1);
          }} color="secondary">
            Submit
          </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Drawer anchor={"right"} open={drawer} onClose={toggleDrawer}>
        <Filters
          sensors={props.sensors}
          toggleDrawer={toggleDrawer}
          id={props.service}
          history={props.history}
          serviceDashboard={true}
        />
      </Drawer>
      <div
        style={{
          fontWeight: "bold",
          // display: "flex",
          gap: "12px",
          justifyContent: "end",
          alignItems: "end",
          position: isFetching ? "absolute": "relative",
          left: "10px",
          bottom: "0px"
        }}
      >
        <div>
          {" "}
          <span>
            <img src={Rensair} style={{ width: "170px", marginBottom: "2px" }} />
          </span>
        </div>
      </div>
    </Fragment>
  );
}
